import type { PriceChangeQuery } from './definitions'
import type { PriceChange } from '~/types/domain/priceChange'

export const extractOverviewFactbox = (query: PriceChangeQuery, changes: PriceChange[]) => {
  return changes.find(
    ({ category, shippingRoute, grade }) =>
      query.category === category.name &&
      query.shippingRegionName === shippingRoute.name &&
      (!query.grade ? true : query.grade === grade),
  )
}
