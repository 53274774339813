import { TOP_PRODUCERS_LIMIT, TOP_PRODUCERS_COLUMN_DEFS } from '~/constants/homepage'
import { useLithiumSupplyMinedCompanyStore } from '~/stores/lithium/supply/mined/company'
import { useLithiumSupplyChemicalCompanyStore } from '~/stores/lithium/supply/chemical/company'
import { useLithiumSupplyMinedCountryStore } from '~/stores/lithium/supply/mined/country'
import { useLithiumSupplyChemicalCountryStore } from '~/stores/lithium/supply/chemical/country'
import { useLithiumSupplyChemicalProductStore } from '~/stores/lithium/supply/chemical/product'

export const useHomeProducers = () => {
  const lithiumSupplyChemicalCompanyStore = useLithiumSupplyChemicalCompanyStore()
  const { fetchHomeProducers: fetchChemicalCompanyProducers } = lithiumSupplyChemicalCompanyStore
  const lithiumSupplyMinedCompanyStore = useLithiumSupplyMinedCompanyStore()
  const { fetchHomeProducers: fetchMinedCompanyProducers } = lithiumSupplyMinedCompanyStore

  const lithiumSupplyMinedCountryStore = useLithiumSupplyMinedCountryStore()
  const { fetchHomeProducers: fetchMinedCountryProducers } = lithiumSupplyMinedCountryStore
  const lithiumSupplyChemicalCountryStore = useLithiumSupplyChemicalCountryStore()
  const { fetchHomeProducers: fetchChemicalCountryProducers } = lithiumSupplyChemicalCountryStore

  const lithiumSupplyChemicalProductStore = useLithiumSupplyChemicalProductStore()
  const { fetchHomeProducers: fetchChemicalProductProducers } = lithiumSupplyChemicalProductStore

  const companyTopProducersDropdownValue = ref('chemical')
  const countryTopProducersDropdownValue = ref('chemical')
  const heatMapDropdownValue = ref('chemical')

  const getCompanyStore = () => {
    if (companyTopProducersDropdownValue.value === 'chemical') {
      return lithiumSupplyChemicalCompanyStore
    }
    if (companyTopProducersDropdownValue.value === 'mined') {
      return lithiumSupplyMinedCompanyStore
    }
  }

  const getCountryStore = () => {
    if (countryTopProducersDropdownValue.value === 'chemical') {
      return lithiumSupplyChemicalCountryStore
    }
    if (countryTopProducersDropdownValue.value === 'mined') {
      return lithiumSupplyMinedCountryStore
    }
  }

  const mapOverviewData = computed(() => {
    const mapStore =
      heatMapDropdownValue.value === 'chemical'
        ? lithiumSupplyChemicalCountryStore
        : lithiumSupplyMinedCountryStore

    if (!mapStore.home)
      return { countries: [], year: '', unit: '', weighted: false, hasWeightProperty: false }

    const hasWeightProperty = existProperty(mapStore.home, 'weighted')

    const { countries, year, unit, weighted } = mapStore.home

    return { countries, year, unit, weighted, hasWeightProperty }
  })

  const countryTopProducers = computed(() => {
    const countryStore = getCountryStore()
    if (!countryStore.home) return { data: [], columnDefs: [], year: null }

    const topProducers = [...toRaw(countryStore?.home?.producers)].splice(0, TOP_PRODUCERS_LIMIT)
    if (!topProducers) return { data: [], columnDefs: [], year: null }

    const columnDefs = TOP_PRODUCERS_COLUMN_DEFS

    const data = topProducers.map(({ marketShare, productionWithUnit, name }) => ({
      name,
      marketShare,
      production: productionWithUnit,
    }))
    const goto = `/lithium/supply/${countryTopProducersDropdownValue.value}/countries/overview/`

    return {
      data,
      columnDefs,
      year: countryStore?.home?.year,
      goto,
      weighted: countryStore.home.weighted,
    }
  })

  const companyTopProducers = computed(() => {
    const companyStore = getCompanyStore()

    if (!companyStore.home) return { data: [], columnDefs: [], year: null }
    const topProducers = [...toRaw(companyStore?.home?.producers)].splice(0, TOP_PRODUCERS_LIMIT)

    if (!topProducers) return { data: [], columnDefs: [], year: null }

    const columnDefs = TOP_PRODUCERS_COLUMN_DEFS

    const data = topProducers.map(({ marketShare, productionWithUnit, name }) => ({
      name,
      marketShare,
      production: productionWithUnit,
    }))

    const goto = `/lithium/supply/${companyTopProducersDropdownValue.value}/companies/overview/`

    return {
      data,
      columnDefs,
      year: companyStore?.home?.year,
      goto,
      weighted: companyStore.home.weighted,
    }
  })

  const chemicalProductProducers = computed(() => {
    const chemicalProductStore = lithiumSupplyChemicalProductStore
    return chemicalProductStore.home
  })

  const clearWeightedUnweightedState = () => {
    lithiumSupplyChemicalCountryStore.home = null
    lithiumSupplyMinedCountryStore.home = null
    lithiumSupplyChemicalCompanyStore.home = null
    lithiumSupplyMinedCompanyStore.home = null
  }

  const changeCountryTopProducersDropdownValue = (option: string) => {
    countryTopProducersDropdownValue.value = option
  }

  const changeCompanyTopProducersDropdownValue = (option: string) => {
    companyTopProducersDropdownValue.value = option
  }

  const changeHeatMapDropdownValue = (option: string) => {
    heatMapDropdownValue.value = option
  }

  const fetchTopProducers = async () => {
    await Promise.all([
      fetchChemicalCompanyProducers(TOP_PRODUCERS_LIMIT),
      fetchMinedCompanyProducers(TOP_PRODUCERS_LIMIT),
      fetchMinedCountryProducers(),
      fetchChemicalCountryProducers(),
      fetchChemicalProductProducers(),
    ])
  }

  return {
    companyTopProducersDropdownValue,
    companyTopProducers,
    countryTopProducersDropdownValue,
    countryTopProducers,
    heatMapDropdownValue,
    mapOverviewData,
    chemicalProductProducers,
    changeCountryTopProducersDropdownValue,
    changeCompanyTopProducersDropdownValue,
    fetchTopProducers,
    changeHeatMapDropdownValue,
    clearWeightedUnweightedState,
  }
}
