import { PriceChangeQuery } from './definitions'

export const LITHIUM_OVERVIEW_FACTBOX_QUERIES: { [key: string]: PriceChangeQuery } = {
  'Lithium-Carbonate-Global-Weighted-Average': {
    category: 'Lithium Carbonate',
    shippingRegionName: 'Global Weighted Average',
  },
  'Lithium-Carbonate-EXW-China': {
    category: 'Lithium Carbonate',
    shippingRegionName: 'EXW China',
    grade: 'Battery',
  },
  'Lithium-Hydroxide-EXW-China': {
    category: 'Lithium Hydroxide',
    shippingRegionName: 'EXW China',
  },
}
